import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "./Core";
// import { device } from "../utils";
import Logo from "./Logo";

const PagesSection = styled.div`
  padding: 80px 0 60px;
`;

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

/* const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  .dropdown-toggle {
    cursor: pointer;
  }
  > li {
    > .nav-link {
        color: ${({ dark, theme }) =>
          dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 18px !important;
        padding-top: 27px !important;
        @media ${device.md} {
          padding-top: 22px !important;
        }
        @media ${device.lg} {
          padding-top: 27px !important;
        }
    }
  }
  .nav-item.dropdown {
      position: relative;
      z-index: 99;
    &:hover {
      > .menu-dropdown {
          top: 90%;
          opacity: 1;
          pointer-events: visible;
      }
    }
`;

const MenuDropdown = styled.ul`
  list-style: none;
  top: 70%;
  position: absolute;
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding: 15px 0px;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.4s, top 0.4s;
  pointer-events: none;
  left: 0%;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media ${device.md} {
    grid-template-columns: 1fr;
  }
  @media ${device.lg} {
    grid-template-columns: 1fr 1fr;
  }

  .drop-menu-item {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.dark};
    transition: all 0.3s ease-out;
    position: relative;
    display: flex;
    align-items: center;
    &.dropdown-toggle::after {
      display: inline-block;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.325em solid;
      border-right: 0.325em solid transparent;
      border-bottom: 0;
      border-left: 0.325em solid transparent;
      position: relative;
      top: 1px;
      margin-left: auto;
      transform: rotate(-90deg);
      transition: 0.4s;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.secondary} !important;
      text-decoration: none;
      &::after {
        transform: rotate(0deg);
      }
    }
    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.md} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(100%);
          }
        }
      }
      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.primary};
        top: 10px;
        left: 0%;
        opacity: 0;
        transform: translateX(-110%);
        transition: 0.4s;
        pointer-events: none;
        .drop-menu-item {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  &.dropdown-right {
    left: auto;
    right: 0;
  }
`; */

const StyledLink = styled(Link)`
  color: ${({ theme, color }) => theme.colors[color]} !important;
`;

const StyledUrl = styled.a`
  color: ${({ theme, color }) => theme.colors[color]} !important;
  display: flex;
  align-items: center;
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({
  isDark = true,
  langs,
  langNames,
  menu,
  homeLink,
  langKey,
  defaultLangKey,
}) => {
  return (
    <>
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <PagesSection>
            <Row className="justify-content-center">
              <Col lg="2" md="4">
                <Logo homeLink={homeLink} white={isDark} />
                {/* <div className="navbar-nav ml-md-auto mr-3">
                  <Menu className="navbar-nav d-flex" dark={isDark ? 1 : 0}>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-globe" />
                        {langs.map(
                          (lang) =>
                            lang.selected && (
                              <span key={lang.langKey}>
                                {" " + langNames[lang.number]}
                              </span>
                            )
                        )}
                      </a>
                      <MenuDropdown
                        className="menu-dropdown"
                        dark={isDark ? 1 : 0}
                      >
                        {langs.map(
                          (lang) =>
                            !lang.selected && (
                              <Link to={lang.link} key={lang.langKey}>
                                <li className="drop-menu-item">
                                  {langNames[lang.number]}
                                </li>
                              </Link>
                            )
                        )}
                      </MenuDropdown>
                    </li>
                  </Menu>
                </div> */}
              </Col>
              <Col lg="10" md="8" className="mt-5 mt-lg-0">
                <Row>
                  {menu.map((column, i) => (
                    <Col xs="6" lg="3" key={i}>
                      <div className="mb-5 mb-lg-4">
                        <TitleStyled
                          variant="card"
                          color={isDark ? "light" : "dark"}
                        >
                          <FormattedMessage id={"menu." + column.label} />
                        </TitleStyled>
                        <UlStyled>
                          {column.navitem.map((item, i) =>
                            item.blank ? (
                              <StyledUrl
                                key={i}
                                color={isDark ? "lightShade" : "darkShade"}
                                href={
                                  item.slug +
                                  "/" +
                                  (langKey === "pl" ? "" : langKey)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <li>
                                  <FormattedMessage id={"menu." + item.label} />
                                </li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                  css={`
                                    fill: currentcolor;
                                    vertical-align: middle;
                                    height: 1rem;
                                    width: 1rem;
                                    margin-left: 10px;
                                  `}
                                >
                                  <path d="M384 32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384zM160 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24H160z" />
                                </svg>
                              </StyledUrl>
                            ) : (
                              <StyledLink
                                to={`${
                                  langKey !== defaultLangKey
                                    ? `/${langKey}`
                                    : "/"
                                }${item.slug}`.replace("//", "/")}
                                key={i}
                                color={isDark ? "lightShade" : "darkShade"}
                              >
                                <li>
                                  <FormattedMessage id={"menu." + item.label} />
                                </li>
                              </StyledLink>
                            )
                          )}
                        </UlStyled>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </PagesSection>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; {new Date().getFullYear()} Tournify</p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/TournifyApp"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/tournifyapp/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram"
                    >
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/tournify"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
